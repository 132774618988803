<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
.other-document-row:hover {
  background-color: rgba(169, 169, 169, 0.147);
}
.requested-tag {
  margin-left: 140px;
}
</style>
<template>
  <div>
    <div class="tab_header d-flex black--text font-weight-bold px-2 mb-1">
      <div
        style="width: 75%"
        class="d-flex align-center py-3"
        @click="toggleAccordion()"
      >
        <div class="text-body-1 font-weight-medium px-3">
          Other Documents
        </div>
        <span
          class="text-body-2 requested-tag font-weight-bold secondary_2--text"
          v-if="is_other_doc_required"
        >
          <v-icon class="secondary_2--text mr-1">
            mdi-alert-circle-outline </v-icon
          >Requested
        </span>
      </div>
      <div style="width: 25%" class="d-flex align-center justify-end">
        <v-btn
          small
          outlined
          color="secondary_2"
          class="font-weight-bold"
          :disabled="loader || is_other_doc_required"
          :loading="loader"
          @click="requestOtherDocument()"
        >
          Request
        </v-btn>
        <!-- 
        <v-btn
          small
          text
          class="red white--text font-weight-bold mx-2"
          :disabled="btn_loader || loader"
          :loading="btn_loader || loader"
        >
          Reject
        </v-btn> -->
        <v-icon
          color="black"
          class="mx-3"
          v-if="show"
          @click="toggleAccordion()"
        >
          mdi-chevron-up
        </v-icon>
        <v-icon color="black" class="mx-3" v-else @click="toggleAccordion()">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>
    <div v-if="show" class="py-2">
      <DivLoader
        v-if="loader"
        :loading_type="'list-item-two-line, list-item-two-line'"
      ></DivLoader>
      <template v-else>
        <div v-if="other_documents[0]">
          <v-row
            class="other-document-row ma-0 px-3"
            v-for="(item, index) in other_documents"
            :key="item.id"
          >
            <v-col cols="12" class="d-flex align-center justify-space-between">
              <div class="d-flex">
                <p class="field-content mr-2">{{ index + 1 }}.</p>
                <div>
                  <p class="field-content">
                    {{
                      item.document_type
                        .replace(/_/g, " ")
                        .toLowerCase()
                        .split(" ")
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(" ")
                    }}
                  </p>
                  <p class="error--text text-caption">
                    {{ item.rejection_reason }}
                  </p>
                </div>
              </div>
              <!-- </v-col> -->
              <!-- <v-col cols="4" class="d-flex align-center justify-end"> -->
              <div>
                <v-btn
                  small
                  text
                  @click="
                    isImageDocument(item.document)
                      ? previewImage(item.document, item.document_type)
                      : viewDocument(item.document)
                  "
                  class="indigo lighten-5 py-4 ml-2"
                >
                  <v-icon color="deep-purple darken-4">mdi-eye-outline</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <template v-else>
          <p class="text-center font-weight-bold">No Documents Available</p>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      show: true,
      address_proof: [],
      loader: false,
      btn_loader: false,
      other_documents: [],
      is_other_doc_required: false,
    };
  },
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
  },
  computed: {
    ...mapGetters({
      customer_id: "pre_verification/getCustomerId",
      active_loan_id: "pre_verification/getActiveLoanId",
    }),
  },
  mounted() {
    this.getOtherDocument();
  },
  methods: {
    toggleAccordion() {
      this.show = !this.show;
      if (this.show) {
        this.getOtherDocument();
      }
    },

    // view document
    viewDocument(url) {
      window.open(url, "_blank");
    },

    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(url) {
      let ext = url.split(".");
      ext = ext[ext.length - 1];
      return ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "webp";
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(url, document_name) {
      // console.log("image preview", url);
      let image_url = url;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_name),
        },
      });
      window.open(route.href, "_blank");
    },

    getOtherDocument() {
      const self = this;

      self.loader = true;
      let params = {
        loan_id: self.active_loan_id,
        customer_id: self.decrypt(this.$route.params.id),
      };
      // console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          self.other_documents = response.data.other_doc_list;
          self.is_other_doc_required = response.data.is_other_doc_required;
        }
      };
      const finallyHandler = () => {
        self.loader = false;
      };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_GET_OTHER_DOCUMENT,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    requestOtherDocument() {
      if (this.active_loan_id) {
        const self = this;

        let form = new FormData();
        form.append("loan_id", self.active_loan_id);
        form.append("document_type", "other");

        const successHandler = (response) => {
          if (response.data.success) {
            this.showSnakeBar("success", "Other Document Requested Again");
            this.getOtherDocument();
          }
        };
        const finallyHandler = () => {};
        self.request_POST(
          self,
          self.$urls.NEW_CUSTOMER_REQUEST_OTHER_DOCUMENT,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else {
        this.showSnakeBar("error", "No active loan available");
      }
    },
  },
};
</script>
